/** @format */

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ContentSection from "../components/ContentSection"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import EqualSquare from "../components/EqualSquare"
import HyperLinkText from "../components/HyperLinkText"

// eslint-disable-next-line
export const DownloadPageTemplate = ({
  title,
  section1,
  section2,
  section3,
}) => {
  const section1asset = getImage(section1.image) || section1.image
  const section2asset = getImage(section2.image) || section2.image
  const section3asset = getImage(section3.image) || section3.image

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-6-desktop">
              <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
                {title}
              </h2>
              <p className="subtitle has-text-grey mb-5">
                Looking to make your own flag-themed gear? Download graphics
                files and check out flag color information here.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContentSection backgroundColorClass={"has-background-light"}>
        <div className="columns is-multiline">
          <div className="column is-12 is-justify-content-right">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              Community property
            </h2>
            <p className="subtitle has-text-grey mb-5">
              The new Raleigh flag design is licensed under{" "}
              <HyperLinkText
                link={"https://creativecommons.org/publicdomain/zero/1.0/"}
                text={"Creative Commons Zero"}
              />
              . This means you may copy, modify, distribute, and use the design
              for free, including for commercial purposes.​
            </p>
          </div>
        </div>
        <div className="is-vcentered columns is-multiline my-5 py-3">
          <div className="column is-7 mr-auto">
            <GatsbyImage
              image={section1asset}
              objectFit={"cover"}
              layout="fullWidth"
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="column is-4">
            <div className="mb-5">
              <a
                className="button is-primary is-fullwidth-mobile "
                href="/img/ral_flag_production.png"
                download
              >
                Download .png
              </a>
            </div>
            <div>
              <a
                className="button is-primary is-fullwidth-mobile "
                href="/img/ral_flag_production.svg"
                download
              >
                Download .svg
              </a>
            </div>
          </div>
        </div>
      </ContentSection>
      <ContentSection backgroundColorClass={""}>
        <div className="is-vcentered columns is-multiline my-5 py-3">
          <div className="column is-7 mr-auto">
            <GatsbyImage
              image={section2asset}
              objectFit={"cover"}
              layout="fullWidth"
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="column is-4">
            <div className="mb-5">
              <a
                className="button is-primary is-fullwidth-mobile "
                href="/img/ral_logo_transparent.png"
                download
              >
                Download .png
              </a>
            </div>
          </div>
        </div>
      </ContentSection>
      <ContentSection backgroundColorClass={"has-background-light"}>
        <div className="is-vcentered columns is-multiline my-5 py-3">
          <div className="column is-7 mr-auto">
            <GatsbyImage
              image={section3asset}
              objectFit={"cover"}
              layout="fullWidth"
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="column is-4">
            <div className="mb-5">
              <a
                className="button is-primary is-fullwidth-mobile "
                href="/img/ralflag_circle.png"
                download
              >
                Download .png
              </a>
            </div>
          </div>
        </div>
      </ContentSection>
      <ContentSection backgroundColorClass={""}>
        <div className="columns is-multiline">
          <div className="column is-12 is-justify-content-right">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              Flag color info
            </h2>
          </div>
        </div>
        <div className="is-vcentered columns is-multiline my-5 py-3">
          <div className="column is-fullwidth is-6 mb-5">
            <EqualSquare backgroundColor={"#c8102e"}>
              <h4 className="mb-3 pt-3 is-size-4 has-text-light has-text-weight-bold">
                Old Glory Red
              </h4>
              <h4 className="is-size-5 has-text-light has-text-weight-bold">
                PMS 186
              </h4>
              <h4 className="is-size-5 has-text-light has-text-weight-bold">
                RGB: 200/16/46
              </h4>
              <h4 className="is-size-5 has-text-light has-text-weight-bold">
                Hex: #c8102e
              </h4>
              <h4 className="is-size-5 has-text-light has-text-weight-bold">
                CMYK: 0/92/77/22​
              </h4>
            </EqualSquare>
          </div>
          <div className="column is-fullwidth is-6 mb-5">
            <EqualSquare backgroundColor={"#d0d3d4"}>
              <h4 className="mb-3 pt-3 is-size-4 has-text-dark has-text-weight-bold">
                Silver
              </h4>
              <h4 className="is-size-5 has-text-dark has-text-weight-bold">
                PMS 427
              </h4>
              <h4 className="is-size-5 has-text-dark has-text-weight-bold">
                RGB: 208/211/212
              </h4>
              <h4 className="is-size-5 has-text-dark has-text-weight-bold">
                Hex: #d0d3d4
              </h4>
              <h4 className="is-size-5 has-text-dark has-text-weight-bold">
                CMYK:  2/0/0/17
              </h4>
            </EqualSquare>
          </div>
        </div>
      </ContentSection>
    </div>
  )
}

DownloadPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  section1: PropTypes.object,
  section2: PropTypes.object,
  section3: PropTypes.object,
}

const DownloadPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <DownloadPageTemplate
        title={frontmatter.title}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
        section3={frontmatter.section3}
      />
    </Layout>
  )
}

DownloadPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DownloadPage

export const DownloadPageQuery = graphql`
  query DownloadPage {
    markdownRemark(frontmatter: { templateKey: { eq: "download-page" } }) {
      frontmatter {
        title
        section1 {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section2 {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section3 {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
