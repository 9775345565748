import React from "react"

const EqualSquare = ({ children, backgroundColor }) => {
  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{
          marginTop: "100%",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: backgroundColor,
        }}
        className="box"
      >
        {children}
      </div>
    </div>
  )
}

export default EqualSquare
